<template>
  <div style="color: #000;height: 100%" class="qr_code">
    <div class="tops">
      <div class="bjin">
        <van-icon class="letioc" @click="back" name="arrow-left" />
        <span>套餐超市</span>
      </div>

      <img class="tcimg" src="../../../assets/newimg/taocanliucheng.png" alt />
    </div>
    <div class="mian">
      <div class="mian_a" v-for="(item,idx) in list" :key="idx">
        <div class="h1" @click.stop="xuanze(idx)">
          <van-radio-group v-model="radio">
            <van-radio checked-color="#68E394" :name="idx" icon-size="24px" class="left xuanz"></van-radio>
          </van-radio-group>
          <!-- <van-radio v-model="item.radio" icon-size="24px" class="left xuanz"></van-radio> -->
          <!-- <van-checkbox icon-size="24px" class="left xuanz" v-model="checked"></van-checkbox> -->
          <div class=" topname">
            <div class="lis_name">{{item.name}}</div>
            <!-- <div class="lis_riq">{{item.startDate}}-{{item.endDate}}</div> -->
          </div>
          <div class="">
            <div class="lis_jin">
              <span class="dan">¥</span>
              <span>{{item.price}}</span>
            </div>
            <!-- <div class="lis_jin2">
              <del>¥{{item.sellPrice}}</del>
            </div> -->
          </div>
        </div>
        <div class="h2" @click="tourl(item)">
          <div class="kaname">{{item.className}}</div>
          <div class="left ka_lis">
            <div class="qiu">
              <div class="suo tkci">{{item.names1}}</div>
              <div class="suo">{{item.names2}}</div>
            </div>
            <div class="fang">
              <img class="fang_img" v-if="item.image" :src="item.image" alt />
              <img class="fang_img" v-else src="../../../assets/img/mendian.png" alt />
            </div>
            <div class="tishi">*购买后一年有效</div>
          </div>
          <div class="left karis">
            <div class="han_A">年卡详细包含：</div>
            <div class="han_b">
              <div class="xingmulis" v-for="(is,index) in item.activityItems" :key="index">
                <div v-for="(it,ist) in is.items" :key="ist">
                  <span class="left yichu">{{it.itemName}}</span>
                  <span class="right">×{{it.totalTimes}}</span>
                </div>
              </div>
            </div>
            <div class="heng"></div>
            <div class="tis">*以上项目包含工时费</div>
          </div>
        </div>
      </div>
    </div>

    <div class="botts">
      <div class="left">
        <span class="hjine_a">¥</span>
        <span class="hjine_b">{{form.activityPrice?form.activityPrice:0.00}}</span>
        <span class="hjine_c">含服务费</span>
      </div>
      <van-button round type="info" class="fukuan right" @click="goumai" color="#68e394">去结算</van-button>
    </div>
  </div>
</template>

<script>
import {
  taocanlist,
  getGarageInfo,
  memberCombo,
  config,
  checkteam,
  defaultcarInfo,
} from "../../../api/check.js";
import utils from "../../../assets/utils";
export default {
  data() {
    return {
      list: [],
      radio: null,
      userInfo: JSON.parse(localStorage.getItem("userInfo")),
      shangjia: JSON.parse(sessionStorage.getItem("shangjia")),
      radio: "1",
      loading: false,
      checked: false,
      billNumbe: "",
      oid: "",
      totalMoney: "",
      gid: "",
      shopname: "",
      id: 0,
      cid: "",
      tcid: 0,
      form: {},
      downloadData: {},
    };
  },
  methods: {
    xuanze(idx) {
      console.log(idx);
      this.radio = idx;
      this.tcid = this.list[idx].id;
      this.form = this.list[idx];
      this.getConfig();
    },
    goumai() {
      if (this.form.id) {
        this.$router.push({
          path: "ka_pay",
          query: {
            isok: true,
            cid: this.$route.query.cid,
          },
        });
        this.form.type = 17;
        this.form.activityPrice = item.price;
        this.form.activityUrl = item.image;
        this.form.name = item.name;
        sessionStorage.setItem("huiyuan", JSON.stringify(this.form));
      } else {
        this.$toast("请先选择套餐！");
      }
    },
    toxuanze(e) {
      console.log(e);

      this.form = this.list[e];
    },
    back() {
      if (this.$route.query.cid) {
        this.$router.push({
          path: "user",
        });
      } else {
        this.$router.go(-1);
      }
    },
    timeVip() {
      var _this = this;
      checkteam({ uid: this.userInfo.id }).then((e) => {
        if (e.code == 200) {
          this.tuanisok = e.data;
          this.$nextTick(() => {
            _this.getConfig();
          });
          console.log(this.tuanisok);
        }
      });
    },
    onLoad() {
      var _this = this;
      const loading = this.$toast.loading({
        mask: true,
        duration: 0,
        message: "加载中...",
      });

      const data = {
        gid: this.$route.query.gid,
        page: 0,
        size: 50,
        // gid: 1
      };
      memberCombo(data)
        .then((e) => {
          // console.log(this.radio);
          loading.clear();
          console.log(_this.$route.query.tcid);
          this.list = e.data.content;
          this.list.forEach(function (item, idx) {
            if (item.labelName && item.labelName.length == 5) {
              item.names1 = item.labelName.slice(0, 2);
              item.names2 = item.labelName.slice(2, 5);
            } else {
              item.names1 = "";
              item.names2 = "";
            }
            item.radio = idx;
            if (_this.$route.query.tcid) {
              if (item.id == _this.$route.query.tcid) {
                _this.radio = idx;
                _this.tcid = item.id;
                _this.form = item;
              }
            }
          });
          console.log(this.tcid);
        })
        .catch(() => loading.clear());
    },
    tourl(item) {
      this.$router.push({
        path: "/supermarket_details",
        query: {
          id: item.id,
          gid: this.shangjia.id,
        },
      });
    },
    //获取车辆信息
    getmodel() {
      //
      defaultcarInfo().then((e) => {
        console.log(e.data);
        if (e.data == undefined) {
          sessionStorage.setItem("chexin", null);
        } else {
          this.chexin = e.data;
          sessionStorage.setItem("chexin", JSON.stringify(e.data));
        }
        // if(e.data)
      });
    },
    getConfig() {
      let that = this;
      config({
        url: location.href.split("#")[0],
        gid: sessionStorage.getItem("Agid")
          ? sessionStorage.getItem("Agid")
          : 208,
      }).then((res) => {
        if (res.code == 200) {
          console.log(res);
          wx.config({
            debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
            appId: res.data.appid, // 必填，公众号的唯一标识
            timestamp: res.data.timestamp, // 必填，生成签名的时间戳
            nonceStr: res.data.noncestr, // 必填，生成签名的随机串
            signature: res.data.signature, // 必填，签名
            jsApiList: ["updateAppMessageShareData"],
          });
          console.log(this.tuanisok);
          if (this.tuanisok == true) {
            console.log("asdad");
            var link =
              "https://shopping.car-posthouse.cn/supermarket?gid=" +
              this.shangjia.id +
              "&cid=" +
              this.userInfo.id +
              "&denglu=" +
              true +
              "&tcid=" +
              this.tcid;
          } else {
            var link =
              "https://shopping.car-posthouse.cn/supermarket?gid=" +
              this.shangjia.id +
              "&cid=" +
              this.$route.query.cid +
              "&denglu=" +
              true +
              "&tcid=" +
              this.tcid;
          }

          var desc =
            that.userInfo.realName + "已参与活动，邀你一起参与共享优惠";
          wx.ready(() => {
            wx.updateAppMessageShareData({
              title: "东莞黄江驿车驹·宏开店优惠套餐超市",
              desc: desc,
              link: link,
              imgUrl: this.form.image
                ? this.form.image
                : "http://vehicle-repairs.oss-cn-hangzhou.aliyuncs.com/test/3494/163877524258233.png?Expires=1954135242&OSSAccessKeyId=LTAI4FhBMt2eaMxuMMSCECEm&Signature=PwQzEWBL5mt69kChKWmzeVhAFf8%3D",
              success: function () {},
            });
          });
        } else {
          this.$toast(res.data.msg);
        }
      });
    },
  },
  created() {
    if (localStorage.getItem("Authorization") == null) {
      // if (this.$route.query.denglu != undefined) {
      var url =
        this.$route.path +
        "?gid=" +
        this.$route.query.gid +
        "&cid=" +
        this.$route.query.cid;
      localStorage.setItem("beforeUrl", url);
      sessionStorage.setItem("Agid", this.$route.query.gid);
      sessionStorage.setItem("gid", this.$route.query.gid);
      sessionStorage.setItem("cid", this.$route.query.cid);
      getGarageInfo({ gid: this.$route.query.gid })
        .then((e) => {
          // loading.clear();
          this.shangjia = e.data;
          sessionStorage.setItem("appid", e.data.idd);
          sessionStorage.setItem("shangjia", JSON.stringify(this.shangjia));
          // 正式
          const redirect_uri = encodeURIComponent(
            "https://shopping.car-posthouse.cn/auths"
          );
          window.location.href = `https://marketing.car-posthouse.cn/get-weixin-code.html?appid=${e.data.idd}&redirect_uri=${redirect_uri}&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect`;
        })
        .catch(() => loading.clear());
      return;
    } else {
      getGarageInfo({ gid: this.$route.query.gid })
        .then((e) => {
          // loading.clear();
          this.shangjia = e.data;
          sessionStorage.setItem("appid", e.data.idd);
          sessionStorage.setItem("shangjia", JSON.stringify(this.shangjia));
        })
        .catch(() => loading.clear());
      this.getmodel();
    }
    var isok = localStorage.getItem("isok");
    if (isok == "true") {
      setTimeout((res) => {
        this.$router.go(0);
      }, 100);
      localStorage.removeItem("isok");
    }

    this.timeVip();
    this.onLoad();
  },
  mounted() {},
};
</script>

<style lang="less" scoped>
.tops {
  // height: 46px;

  background: #fff;
  text-align: center;
  color: #fff;
  line-height: 46px;
  font-size: 17px;
  font-weight: bold;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 99;
}
.bjin {
  width: 100%;
  background: #68e394;
}
.letioc {
  position: absolute;
  float: left;
  top: 0;
  left: 0;
  z-index: 2;
  margin-left: 5px;
  margin-top: 14px;
  font-size: 18px;
}
.tcimg {
  display: block;
  width: 100%;
  border-bottom: 6px solid #f1f1f1;
}
.mian {
  padding-top: 117px;
  padding-bottom: 60px;
}
.mian_a {
  background: url(../../../assets/newimg/kabeijin.png);
  background-size: 100%;
  width: 360px;
  height: 216px;
  margin: auto;
  position: relative;
}
.h1 {
  overflow: hidden;
  padding: 0 20px;
  display: flex;
  align-items: center;
}
.xuanz {
  margin-top: 20px;
}
.lis_name {
  font-weight: 600;
  color: #000000;
  line-height: 20px;
  font-size: 14px;
  margin-top: 15px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 237px;
}
.lis_riq {
  font-size: 12px;
  font-weight: 400;
  color: #999999;
  line-height: 17px;
}
.topname {
  margin-left: 6px;
}
.lis_jin {
  font-weight: 500;
  color: #d70000;
  line-height: 25px;
  font-size: 18px;
  margin-top: 14px;
  text-align: right;
}
.lis_jin2 {
  font-size: 12px;
  font-weight: 600;
  color: #666666;
  line-height: 17px;
  text-align: right;
}
.dan {
  font-size: 14px;
}
.h2 {
  position: relative;
  overflow: hidden;
}
.kaname {
  position: absolute;
  right: 0;
  top: 0;
  font-size: 14px;
  font-weight: 600;
  text-align: left;
  color: #000000;
  line-height: 32px;
  width: 120px;
  text-align: center;
  margin-right: 8px;
}
.ka_lis {
  width: 110px;
  position: relative;
  margin-left: 8px;
  margin-top: 14px;
}
.qiu {
  width: 36px;
  height: 34px;
  background: radial-gradient(#ffefe1, #e89b66);
  border-radius: 50%;
  font-size: 9px;
  // font-weight: 600;
  font-weight: bold;
  text-align: center;
  color: #9f4d16;
  line-height: 11px;
  margin-left: 10px;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
}
.suo {
  transform: scale(0.85);
}
.tkci {
  margin-top: 5px;
}
.fang {
  width: 86px;
  height: 86px;
  border: 1px solid #f1f1f1;
  border-radius: 9px;
  margin-top: 17px;
  margin-left: 13px;
}
.fang_img {
  width: 85px;
  height: 85px;
  display: block;
}
.tishi {
  font-size: 8px;
  font-weight: 400;
  text-align: left;
  color: #939393;
  text-align: center;
  line-height: 11px;
  transform: scale(0.9);
  margin-top: 8px;
}
.karis {
  overflow: hidden;
  margin-top: 30px;
  margin-left: 14px;
}
.han_A {
  font-size: 13px;
  font-weight: 600;
  color: #fdc097;
  line-height: 17px;
}
.han_b {
  margin-top: 8px;
  font-size: 12px;
  font-weight: 400;
  color: #fff;
  height: 62px;
  // overflow: hidden;
  overflow-y: auto;
}
.heng {
  width: 205px;
  height: 1px;
  opacity: 0.17;
  background: #ffffff;
}
.tis {
  font-size: 8px;
  font-weight: 400;
  text-align: right;
  color: #939393;
  line-height: 25px;
  transform: scale(0.9);
}
.botts {
  width: 100%;
  height: 54px;
  background: #fbfcfc;
  position: fixed;
  bottom: 0;
  left: 0;
  overflow: hidden;
  line-height: 54px;
}
.hjine_a {
  font-size: 17px;
  font-weight: 500;
  color: #d70000;
  margin-left: 12px;
}
.hjine_b {
  font-size: 22px;
  font-weight: 500;
  color: #d70000;
  margin-left: 2px;
}
.hjine_c {
  font-size: 12px;
  font-weight: 500;
  color: #666666;
  margin-left: 8px;
}
.fukuan {
  width: 158px;
  height: 42px;
  border-radius: 21px;
  margin-top: 6px;
  margin-right: 12px;
}
.xingmulis {
  overflow: hidden;
  line-height: 17px;
}

.yichu {
  width: 180px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>